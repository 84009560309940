// About.js

import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Rating from "react-rating-stars-component";
import CallToAction from "../components/Call-to-action";

const Contact = () => {
  return (
    <div className="contact-us">
      <div className="container pt-5 pb-2 contactss">

      <section className="teams-sec pt-5 mt-5">
          <h2 className="title-text text-center branding-1 mt-4">
            Get In Touch with <b className="gradient-class">HawkGlide</b> Today
          </h2>
          <div className="row mb-3 mt-5">
            <div className="col-md-4 text-center">
              <div className="user-image">
                <div className="sticky-content__feature-icon-wrapper m-auto mb-3">
                  <div className="logo-white-wrapperr is-red with-shadow">
                        {/* <div className="login-text hidden-mobile">Login</div> */}
                        <img
                          src={require("../images/location-white.png")} style={{width : '100%'}}
                        />
                  </div>
                </div>
                {/* <img
                  src={require("../images/user-1.jpg")}
                  alt="users"
                  className="img-fluid my-3"
                /> */}
                <p className="text-center mb-0">
                  <strong>Address</strong>
                </p>
                <p className="text-center">78, 1st Floor, Om Heera Panna Mall, Oshiwara, Jogeshwari West. Mumbai 400102.</p>
              </div>
            </div>
            <div className="col-md-4 text-center">
              <div className="user-image">
              <div className="sticky-content__feature-icon-wrapper m-auto mb-3">
                  <div className="logo-white-wrapperr is-red with-shadow">
                        {/* <div className="login-text hidden-mobile">Login</div> */}
                        <img
                          src={require("../images/phone-call.png")} style={{width : '100%'}}
                        />
                  </div>
                </div>
                {/* <img
                  src={require("../images/user-2.jpg")}
                  alt="users 2"
                  className="img-fluid my-3"
                /> */}

                <p className="text-center mb-0">
                  <strong>Phone</strong>
                </p>
                <p className="text-center">+91 99300 13847</p>
              </div>
            </div>
            <div className="col-md-4 text-center">
              <div className="user-image">
              <div className="sticky-content__feature-icon-wrapper m-auto mb-3">
                  <div className="logo-white-wrapperr is-red with-shadow">
                        {/* <div className="login-text hidden-mobile">Login</div> */}
                        <img
                          src={require("../images/mail-white.png")} style={{width : '100%'}}
                        />
                  </div>
                </div>
                {/* <img
                  src={require("../images/user-3.jpg")}
                  alt="users 3"
                  className="img-fluid my-3"
                /> */}

                <p className="text-center mb-0">
                  <strong>Email</strong>
                </p>
                <p className="text-center">hello@hawkglide.com</p>
              </div>
            </div>
          </div>

        </section>
      
      <div className="contact-form">
      <form method="post">
            <h1 className="gradient-class mb-3 text-center">Contact Us</h1>
            <h4 className="mb-4 font-22 text-center">Please complete the form below, and we will reach out to you within the next business day.</h4>
            <div className="form-group">
                <input type="text" name="name" className="form-control" placeholder="Your Name *" required />
            </div>
            <div className="form-group">
                <input type="email" name="email" className="form-control" placeholder="Your Email *" required />
            </div>
            <div className="form-group">
                <input type="text" name="subject" className="form-control" placeholder="Subject *" required />
            </div>
            <div className="form-group">
                <textarea name="message" className="form-control" placeholder="Your Message *" rows="5" required></textarea>
            </div>
            <div className="form-group text-center d-flex" style={{justifyContent : 'center'}}>
                <button type="submit" className="btnContact animated-button menu-item text-white text-decoration-none d-block branding1 red-hover border-rounded">Send Message</button>
            </div>
        </form>
        </div>
        
       
      </div>
      <CallToAction />
    </div>
  );
};

export default Contact;
